/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS710M
 * 화면 설명: 피보험자 지문촬영 동의화면
 */
<template>
  <ur-page-container class="msp" title="피보험자 지문촬영" :show-title="true" :topButton="true" type="subpage" 
      @on-header-left-click="fn_HeaderBackBtnHandler"> 

    <!-- step === 1 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap" v-show="step === 1">
      <ur-box-container alignV="start" componentid="" direction="column" class="pb30">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb">본인인증</span>
          <div><span class="fs20rem crTy-blue2 fwb mr2">1</span>/<span class="ml2">3</span></div>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 pb20 bd-b-Ty1">
          <div class="fexTy3 full">
            <span class="fs19rem fwb txtSkip flex-1 ">{{info.custNm}}</span>
            <mo-badge class="badge-sample-badge lightyellow sm ml10" text="피보험자" shape="status">피보험자</mo-badge>
          </div>
          <div class="mt10 sub-text">
            <span>{{info.age}}세</span><em>|</em><span>{{info.knbStr}}</span>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb30">
          <div class="fs18rem fwm">본인인증</div>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
            <mo-segment-wrapper solid primary v-model="authType1[0].key" class="chip-type-wrap chip-ty1">
              <mo-segment-button v-for="(item, idx) in authTypes" :value="item.key" :key="idx" @click="fn_ClickAuthType1(item)" :disabled="isAuthCompleted">{{item.label}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <!-- 핸드폰 -->
          <ur-box-container direction="column" alignV="start" componentid="" class="ns-verify pal0" v-if="authTypeT">
            <ur-box-container alignV="start" componentid="" direction="column">
              <!-- 본인확인 서비스 이용 동의 -->
              <div class="ns-check arrow fexTy5">
                <mo-checkbox v-model="singleCheckbox1" @input="fn_AgreeCnfrmPop2('Open')" :disabled="disabled" class="crTy-bk7" >본인확인 서비스 이용 동의</mo-checkbox>
              </div>
              <!-- 휴대폰번호 -->
              <div class="verify-call mt20">
                <msp-bottom-select underline class="ns-dropdown-sheet call1" bottom-title="통신사를 선택해 주세요" clearable :disabled="disabled" placeholder="선택" :itemValue="'key'" :itemText="'label'" v-model="telecomType.key" :items="telecomTypes" @selectedItem="fn_TelecomTypeClick" closeBtn/>
                <!-- <msp-bottom-select underline class="ns-dropdown-sheet call2" placeholder="010" /> -->
                <mo-decimal-field mask="phone" ref="mobileNumber" v-model="info.celno" readonly placeholder="000 - 0000 - 0000" />
              </div>
              <mo-button class="ns-btn-round h40 blue-line" :disabled="disabled" v-if="isBeforeSend" @click="fn_validateBeforeSubmit">인증번호 전송</mo-button>
              <mo-button class="ns-btn-round h40 blue-line" :disabled="disabled" v-else @click="fn_validateBeforeSubmit">인증번호 재전송</mo-button>
              <mo-button class="ns-btn-round h40 blue-line" :disabled="disabled" v-if="lvProcess" @click="fn_authFinish">인증번호 패스</mo-button>
            </ur-box-container>
            <!-- 휴대폰번호 end -->
            <!-- 인증번호 -->
            <div class="ns-certify-sed pal0 mt50 success">
              <div class="ns-certify-sed-code mb2sam0">
                <div class="left">
                  <mo-text-field  type="number" class="form-input-name" clearable v-model="authNumber" :disabled="disabledAuthNum" mask="######" underline @input="fn_AuthNumKeyUp" ref="cNumber" placeholder="인증번호를 입력해주세요."/>
                  <span class="time crTy-blue2" v-if="!isBeforeSend && !isAuthCompleted">{{confirmTime}}</span>
                </div>
              </div>
              <div class="ns-certify-sed-txt">
                <div class="txt-success" v-if="isAuthCompleted"><mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon>인증되었습니다.</div>
                <div class="txt-error" v-if="isAuthError">인증번호가 다릅니다.</div>
              </div>
              <mo-button class="ns-btn-round h40 blue-line mt20" :disabled="!authNumKeyUpNext" @click="fn_AuthNumKeyUpNext">인증확인</mo-button>             
            </div>
            <!-- 인증번호 -->
            <div class="fs14rem crTy-bk7 mt30">인증번호 미도착시 아래와 같이 조치 부탁드립니다.</div>
            <div class="text-gray-box mt10">
              <ul class="terms-list-area crTy-bk7 fs14rem">
                <li>
                  휴대폰 스팸번호 분류 확인<br>
                  NICE인증 고객센터문의<span class="dsInline ml40">1600 - 1522</span>
                </li>
              </ul>
            </div>
          </ur-box-container>

          <!-- 신용카드 -->
          <ur-box-container direction="column" alignV="start" componentid="" class="ns-verify pal0" v-if="!authTypeT">
            <ur-box-container alignV="start" componentid="" direction="column">
              <!-- 본인확인 서비스 이용 동의 -->
              <!-- <div class="ns-check arrow fexTy5">
                <mo-checkbox v-model="singleCheckbox2" @input="fn_PopCertVnoClick2" :disabled="disabled" class="crTy-bk7" >본인확인 서비스 이용 동의</mo-checkbox>
              </div> -->
              <!-- 휴대폰번호 -->
              <div class="verify-call mt20">
                <!-- <msp-bottom-select underline class="ns-dropdown-sheet call1" placeholder="선택" bottom-title="통신사를 선택해 주세요" closeBtn/> -->
                <msp-bottom-select underline class="ns-dropdown-sheet call2" placeholder="010" :items="celphTlnumCdList" :disabled="disabled" v-model="celphTlnum" @selectedItem="fn_CelphTypeClick"/>
                <mo-decimal-field mask="########" type="number" placeholder="0000 - 0000" ref="celnoRef" clearable :rules="validateRuleCelno" :disabled="disabled" v-model="custTelnum" />
                <!-- <mo-text-field ref="celnoRef" :rules="validateRuleCelno" :disabled="disabled" underline placeholder="0000-0000" type="number" mask="########" v-model="custTelnum" /> -->
              </div>
              <mo-button class="ns-btn-round h40 blue-line" @click="fn_BtnAuthClick" :disabled="disabled">인증하기</mo-button>
              <div class="ns-certify-sed-txt">
                <div class="txt-success" v-if="isAuthConfirm"><mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon>인증되었습니다.</div>
                <div class="txt-error" v-if="isAuthError2">인증에 실패하였습니다.</div>
              </div>
            </ur-box-container>

            <div class="text-gray-box mt30">
              <span class="crTy-bk7 fs14rem">주의사항</span>
              <ul class="terms-list-area crTy-bk7 fs14rem mt4">
                <li>법인카드, 가족카드, 선불카드는 본인확인이 불가합니다.</li>
                <li>휴대폰에 착신전화, 수신거부, 로밍  등이 설정이 되어 있는 경우 서비스 이용이 불가능할 수 있습니다.</li>
                <li>카드사에 등록된 고객님의 휴대폰번호가 변경되었거나 잘못 등록된 경우 변경 후 이용하시기 바랍니다.</li>
              </ul>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
    <!-- step === 1 end -->

     <!-- step === 2 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap" v-show="step === 2">
      <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb">생체정보동의</span>
          <div><span class="fs20rem crTy-blue2 fwb mr2">2</span>/<span class="ml2">3</span></div>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt20 pb20 bd-b-Ty1">
          <div class="fs22rem fwb full">
            <span>생체(지문)정보 동의</span>
          </div>
          <div class="fs16rem fwb full mt20">
            <span>지문촬영은 기존 서면동의서를 대체합니다.
              <br>지점에 서면동의서 원본을 제출할 필요없이 현장에서 피보험자의 동의가 이루어집니다.
            </span>
          </div>
          <div class="fs14rem crTy-bk7 full mt10">
            <span>※ 최소한의 정보처리 및 동의 거부에 관한 안내
              <br>정보동의시 계약의 체결 및 이행 목적달성에 부합하는 최소한의 정보만 수집ᆞ이용 및 제공하며, 본 동의를 거부하시는 경우에는 지문정보를 활용한 계약체결은 불가할 수 있습니다.(서면동의서 작성 필요)
            </span>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area pt16 pb16 bgcolor-3 bd-b-Ty1">
          <div class="chk-box">
            <div class="fwb fs16rem ls--10">
              생체(지문)정보 동의에 관한사항
            </div>
            <div class="ns-check mt10 fexTy2">
              <mo-checkbox v-model="cardTwo.isAllAgree" @input="onSelectAgreeAllTwo" :disabled="cardTwo.isDisabled">전체동의</mo-checkbox>
            </div>
          </div>
        </ur-box-container>

        <!-- btn-area-first(accordion-btn-area이 content보다 먼저오도록 순서조정) title-first(accordion-btn-area 영역안에서 title이 먼저오도록 순서조정) 
              expand-only-btn(accordion-btn-area 영역에만 접기펼치기 이벤트) expanded(기본펼쳐져있음) -->
        <msp-expand btn-area-first title-first expand-only-btn ref="expandedVal1" class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="1" :disabled="cardTwo.isDisabled"></mo-checkbox>
              <span class="fs16rem ml10 ls--15">1. 생체(지문)정보 수집 • 이용에 관한 동의</span>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area">
              <div class="fs14rem crTy-bk7 indent10">
                - 개인(민감)정보의 수집ᆞ이용 목적
                <br>보험계약의 체결ᆞ유지ᆞ관리(부활 및 갱신 포함)ᆞ상담|보험금 등 지급 ᆞ심사|보험계약대출 체결 ᆞ유지 ᆞ관리|서비스 제공|보험모집질서의 유지|보험사기 조사|분쟁 대응 및 민원처리 적부 및 사고조사(보험사기 조사 포함)|보험계약 및 보험금 청구, 보험계약대출에 이해관계가 있는 자에 대한 법규 및 계약상 의무이행 보험금 청구권 실행|위 ᆞ수탁사무의 수행
              </div>
              <div class="fs14rem crTy-bk7 indent10">
                - 수집ᆞ이용할 개인(민감)정보의 항목 : <span class="crTy-blue4">수집ᆞ이용 동의일로부터 거래종료 후 5년까지</span>
                <br>(단, 거래종료후 5년이 경과된후에는 보험금 지급, 금융사고조사, 보험사기방지ᆞ적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유)
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row01 end -->

        <msp-expand btn-area-first title-first expand-only-btn ref="expandedVal2" class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardTwo.agreeCheckboxList" value="2" :disabled="cardTwo.isDisabled"></mo-checkbox>
              <span class="fs16rem ml10 ls--15">2. 생체(지문)정보 제공에 관한 동의</span>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area">
              <div class="fs14rem crTy-bk7 indent10">
                - 개인(민감)정보의 제공받는자 : <b>금융결제원</b>
              </div>
              <div class="fs14rem crTy-bk7 indent10">
                - 제공받는자의 이용 목적 : <b>바이오인증 서비스 제공, 지문정보의 분산보관으로 안정성 제공</b>
              </div>
              <div class="fs14rem crTy-bk7 indent10">
                - 제공하는 항목 : <b>분산 보관용 지문정보 절반</b>(나머지 절반은 삼성생명에서 보관)
              </div>
              <div class="fs14rem crTy-bk7 indent10">
                - 제공받는자의 보유이용기간 : 수집ᆞ이용동의일로부터 거래종료 후 5년까지
                <br>(단, 거래종료후 5년이 경과된후에는 보험금 지급, 금융사고조사, 보험사기방지ᆞ적발, 민원처리, 법령상 의무이행을 위한 경우에 한하여 보유)
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row02 end -->
      </ur-box-container>
    </ur-box-container>
    <!-- step === 2 end -->

    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-show="step === 1" :disabled="!goNext" @click="fn_goNext">다음</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-show="step === 2" :disabled="!cardTwo.isAllAgree" @click="fn_goBioSign">다음</mo-button>
        </div>
      </ur-box-container>
    </template>

    <!-- 이용동의 안내 bottomSheet -->
    <div class="ns-bottom-sheet">
      <mo-bottom-sheet ref="agreeCnfrmPop2" :close-btn="false" class="ns-bottom-sheet closebtn" >
        <template v-slot:title>
          본인확인서비스 이용동의
          <div class="ns-btn-close" @click="fn_AgreeCnfrmPop2('CloseBtn')" name="닫기"></div>
        </template>
        <div class="content-area">
          <ul class="terms-list-area pb36">
            <li>개인정보 수집/이용 동의</li>
            <li>고유식별정보 처리 동의</li>
            <li>통신사 이용약관 동의</li>
            <li>서비스 이용약관 동의</li>
            <li>개인정보 제 3자 제공 동의</li>
          </ul>
        </div>

        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="primary" size="border" class="ns-btn-round white" @click="fn_AgreeCnfrmPop2('Confirm')">약관 확인</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="primary" size="border" class="ns-btn-round blue" @click="fn_AgreeCnfrmPop2('AllConfirm')">전체 약관 동의</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </div>

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPCM010P from '@/ui/cm/MSPCM010P' // 알뜰폰 안내
import Msg from '@/systems/webkit/msg/msg'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS710M', 
  screenId: 'MSPPS710M', 
  components: {
    'MSPCM010P': MSPCM010P
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{},//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      goNext : false,
      authNumKeyUpNext : false,
      step : 1,
      celphTlnumCdList : [], // 전화번호 국번코드
      celphTlnum : '', //선택한 국번
      custTelnum : '' , //인증받을 고객 핸드폰 번호
      // isAuthConfirm : '', //인증여부 Flag
      // chnlCustId : '80478744450192902202', //인증대상 채널 고객 아이디 수리남  this.routerParam.mobslChnlCustId
      validateRuleCelno : [
          v => !!v || '필수입력항목입니다'
         ,v => v.length > 7 || '휴대폰번호를 입력하세요.'
         //,v => this.chkCelnoResult || this.celnoErrorMsg
        ],
      // 주고객 정보 VO
      custCardVO: {
        isExistCustYN:'', //신규 여부
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        chnlCstgrId: '', // 채널그룹ID
        knb: '', // 전체주민번호
        knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '', // 주민번호 앞자리
        knbBkno: '', // 주민번호 뒷자리
        knbBkFstNo: '', // 주민번호 뒷자리 첫자리 (성별구분코드)
        knbBknoCipher: '', // 암호화된 주민번호
        knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
        knbScCd: 'ZPER01', // 식별번호구분코드
        celno: '', // 휴대전화 전체
        celnoFrno: {}, // 휴대전화 앞자리
        celnoBkno: '', // 휴대전화 뒷자리
      },
      //isLoading : false ,
      //chkCelnoResult : false ,  

      // allCheckbox1: false,
      authTypeT: true,
      lvProcess: false,
      confirmTime: '7:00',
      limitTime: 420,
      timer: {},
      isAuthConfirm: false,
      // identityCard: [{
      //   key: '1',
      //   label: '주민등록증/운전면허증'
      // }],
      // identityCardTypes: [{
      //   key: '1',
      //   label: '주민등록증/운전면허증'
      // },
      // {
      //   key: '2',
      //   label: '외국인등록증'
      // }],
      // identityText: '촬영하기',
      // auth: false,
      // isTSSCM115P: false,
      // isTSSCM115PConfirm: false,
      isAuthError: false,
      isAuthError2: false,
      isAuthCompleted: false,
      authResult: {}, // 인증요청 결과
      authNumber: '', // 인증번호 model
      disabledAuthNum: true, // 인증번호 TextField
      isBeforeSend: true,
      // phoneMasking: '###-####-####',
      disabled: false,
      singleCheckbox1: false,
      singleCheckbox2: false,
      prevTelecomType: {key: 'SKT', label: 'SKT'},
      telecomType: {key: 'SKT', label: 'SKT'},
      telecomTypes: [
        {key: 'SKT', label: 'SKT'},
        {key: 'KT', label: 'KT'},
        {key: 'LGT', label: 'LGU+'},
        {key: 'SKTM', label: 'SKT(알뜰폰)'},
        {key: 'KTM', label: 'KT(알뜰폰)'},
        {key: 'LGTM', label: 'LGU+(알뜰폰)'},
        {key: 'ETC', label: '알뜰폰 확인하기'}
      ],
      title: '피보험자 지문촬영',
      // 인중방식
      authTypes: [{
        key: '1',
        label: '휴대폰인증'
      },
      // {
      //   key: '2',
      //   label: '신분증'
      {
         key: '2',
         label: '신용카드'
      }],
      authType1: [{
        key: '1',
        label: '휴대폰인증'
      }],
      // div2
      cardTwo: {
        state: '2',
        allItems: ['1', '2'], // 동의 사항 갯수
        agreeCheckboxList: [],
        marketingCheckboxList: [],
        isAllAgree: false,
        isDisabled: true
      },
      // requiredAgreementContent: [
      //   {
      //     opened: true,
      //     title: '1. 생체(지문)정보 수집·이용에 관한 동의'
      //   },
      //   {
      //     opened: true,
      //     title: '2. 생체(지문)정보 제공에 관한 동의'
      //   }
      // ],
      // confirm1: false,
      routerParam: {},
      info: {}
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    this.lvProcess = process.env.NODE_ENV !== 'production'
    this.routerParam = this.$route.params
    console.log('###################### 넘어온 Param')
    console.log(JSON.stringify(this.routerParam))

    if (!this.routerParam || JSON.stringify(this.routerParam) === '{}') {
      this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {tabIdx: 1})
    } else {
      this.fn_baseData()
    }

    let lv_Vm = this
    let lv_CdList = []
    // ---------------------------------------------------------------------------------
    // 공통업무코드 조회 및 설정 처리
    // ---------------------------------------------------------------------------------
    let param = ['ZA_ELTRN_MAL_DM_CD','ZA_CELPH_KNB_CD'] // 휴대전화국번 코드정보

    window.vue.getInstance('vue').$commonUtil.selListMassEACommCd(param)
    .then(function () {
      // 휴대전화 앞자리 정보 세팅
      let lv_CelphCD = lv_Vm.getStore('code').getters.getState.get('ZA_CELPH_KNB_CD')
      lv_CdList.push({value: '', text: '선택'})
      if (lv_CelphCD && lv_CelphCD.length > 0) {
        lv_CelphCD.forEach((item) => {
            let lv_Item = {}
            lv_Item.value = item.key
            lv_Item.text = item.key
            lv_CdList.push(lv_Item)
          }
        )
      }
      lv_Vm.celphTlnumCdList = lv_CdList
    })

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    // telecomType (n, o) {
    //   if (n.key === 'ETC') {
    //     this.isTSSCM115P = true
    //     this.prevTelecomType = o
    //   }
    // },
    // authType1 (n, o) {
    //   if (n[0].key === '1') {
    //     this.singleCheckbox1 = false
    //     this.fn_endTimer()
    //   }
    // },
    // identityCard () {
    //   if (this.identityCard[0].key === '1') {
    //     this.identityText = '촬영하기'
    //   } else if (this.identityCard[0].key === '2') {
    //     this.identityText = '입력하기'
    //   }
    // },
    'cardTwo.agreeCheckboxList' (v,a){
      if(this.cardTwo.agreeCheckboxList.length === 2){
        this.cardTwo.isAllAgree = true
      }else{
        this.cardTwo.isAllAgree = false
      }
      
      if(v.length == 2){
        this.$refs.expandedVal1.isExpand = false
        this.$refs.expandedVal2.isExpand = false
      }else if(v.length == 1 && v == '1'){
        this.$refs.expandedVal1.isExpand = false
        this.$refs.expandedVal2.isExpand = true
      }else if(v.length == 1 && v == '2'){
        this.$refs.expandedVal1.isExpand = true
        this.$refs.expandedVal2.isExpand = false
      }else{
        this.$refs.expandedVal1.isExpand = true
        this.$refs.expandedVal2.isExpand = true
      }
      
    }

  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    }, //fn_DevicBackBtnHandler
    
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : Header Back 타이틀 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: '피보험자 지문촬영을 중단하시겠습니까?',
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {tabIdx: 1})
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명 : fn_PopCertVnoClick
    * 설명       : 이통사 본인확인 서비스 이용에 따른 동의 나이스 링크 호출 함수
    ******************************************************************************/
    fn_PopCertVnoClick1 (event) {
      if (this.singleCheckbox1) {
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: 'https://cert.vno.co.kr/app/agree/agree_main.jsp'}, () => {}, () => {})
        } else { // PC에서 작동
          window.open('https://cert.vno.co.kr/app/agree/agree_main.jsp', '_blank')
        }
      }
    },
    fn_PopCertVnoClick2 (event) {
      if (this.singleCheckbox2) {
        if (this.$commonUtil.isMobile()) { // 모바일에서 작동
          window.fdpbridge.exec('intentBrowserPlugin', {uri: 'https://cert.vno.co.kr/app/agree/agree_main.jsp'}, () => {}, () => {})
        } else { // PC에서 작동
          window.open('https://cert.vno.co.kr/app/agree/agree_main.jsp', '_blank')
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_AgreeCnfrmPop2
    * 설명       : 본인확인서비스 이용동의 안내 
    *           - OPEN(bottomSheet열기), CloseBtn(창닫기), Confirm(약관 확인) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_AgreeCnfrmPop2(param) {
      switch (param) {
        case 'Open':
          if(this.singleCheckbox1){
            this.singleCheckbox1 = false
            this.$refs.agreeCnfrmPop2.open()
          }
          break
        case 'CloseBtn':
          this.singleCheckbox1 = false
          this.$refs.agreeCnfrmPop2.close()
          break
        case 'Confirm': // 약관 확인
          this.singleCheckbox1 = true
          this.$refs.agreeCnfrmPop2.close()
          this.fn_PopCertVnoClick1() // 동의 나이스 링크 호출
          break
        case 'AllConfirm': // 전체 약관 확인
          this.singleCheckbox1 = true
          this.$refs.agreeCnfrmPop2.close()
          break
      }
    },

    /******************************************************************************
    * Function명 : fn_AuthNumKeyUp
    * 설명       : 인증번호 입력박스 keyup 이벤트 핸들러
    ******************************************************************************/
    fn_AuthNumKeyUpNext () {
      if (this.authNumber.length === 6) {
        // 인증번호 6자리 입력했으니 서비스 태움
        let pParams = {}
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11K1'))
        pParams.data = {}
        pParams.data.srnId = this.$options.screenId // 대표 화면 명
        pParams.data.moblAthntCd = this.authResult.moblAthntCd // 사이트코드
        pParams.data.moblAthntPswd = this.authResult.moblAthntPswd // 사이트패스워드
        pParams.data.respUnqNo = this.authResult.respUnqNo // 응답고유번호
        pParams.data.reqUnqNo = this.authResult.reqUnqNo // 요청고유번호
        pParams.data.athntNo = this.authNumber // 인증번호
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S1', this.fn_ServiceFaultHandler)
        //this.$refs.cNumber.closeKeypad() // 키패드 닫기
      } else{
        this.getStore('toast').dispatch('ADD', '인증번호를 확인하세요')
      }
    },
    fn_AuthNumKeyUp () {
      if (this.authNumber.length === 6) {
        this.authNumKeyUpNext = true
      }else{
        this.authNumKeyUpNext = false
      }
    },

    /******************************************************************************
    * Function명 : fn_ClickAuthType1
    * 설명       : 인증방식 버튼 클릭시
    ******************************************************************************/
    fn_ClickAuthType1(item) {
      let lv_Vm = this
       if (item.key === '1') {
        this.singleCheckbox1 = false
        this.fn_endTimer()
        this.authTypeT = true
      }else{
        this.singleCheckbox2 = false
        this.authTypeT = false
        setTimeout(function () {
          lv_Vm.$refs.celnoRef.focus()
        }, 100)
      }
    },
    /******************************************************************************
    * Function명 : fn_TelecomTypeClick
    * 설명       : 알뜰폰 확인하기 선택 함수
    ******************************************************************************/
    fn_TelecomTypeClick (target) {
      if (target.key === 'ETC') {
        this.popup010 = this.$bottomModal.open(MSPCM010P, {
          properties: {
          },
          listeners: {
            confirmPopup: (pData) => {
              this.$bottomModal.close(this.popup010)
              if (pData !== null && pData !== '') {
                this.$refs.mobileNumber.focus()
              }
              // 파라미터 세팅
              this.fn_ConfirmMSPCM010P(pData)
            }
          }
        })
      } else {
        this.$refs.mobileNumber.focus()
        this.prevTelecomType = Object.assign({}, target)
        this.telecomType = Object.assign({}, target)
      }
    },
    /******************************************************************************
    * Function명 : fn_CelphTypeClick
    * 설명       : 신용카드 선택 함수
    ******************************************************************************/
    fn_CelphTypeClick (target) {
      console.log('target.key::>',target.key)
      if (target.key !== '0') {
        this.$refs.celnoRef.focus()
      } 
    },
    /******************************************************************************
    * Function명 : fn_ConfirmTSSCM115P
    * 설명       : 알뜰폰 안내 팝업창의 '확인' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmMSPCM010P (param) {
      // this.isTSSCM115P = false
      // this.isTSSCM115PConfirm = true
      if (param !== '' && param !== null) {
        if (param.val === 'SKTM') {
          this.telecomType = Object.assign({}, this.telecomTypes[3])
        } else if (param.val === 'KTM') {
          this.telecomType = Object.assign({}, this.telecomTypes[4])
        } else if (param.val === 'LGTM') {
          this.telecomType = Object.assign({}, this.telecomTypes[5])
        } else {
        }
      } else {
        this.telecomType = Object.assign({}, this.prevTelecomType) // 이전에 선택되어 있는 값 복사 후, 매핑
      }
    },
    /******************************************************************************
    * Function명 : fn_CancelTSSCM115P
    * 설명       : 알뜰폰 안내 팝업창의 '취소' 버튼 클릭 시 호출
    ******************************************************************************/
    // fn_CancelTSSCM115P () {
    //   // this.isTSSCM115P = false
    //   // this.isTSSCM115PConfirm = false
    //   this.telecomType = Object.assign({}, this.prevTelecomType) // 이전에 선택되어 있는 값 복사 후, 매핑
    // },
    // fn_PopBtnClicked (param) {
    //   if (param === false && this.isTSSCM115PConfirm === false) {
    //     this.telecomType = Object.assign({}, this.prevTelecomType) // 이전에 선택되어 있는 값 복사 후, 매핑
    //   }
    // },

    /******************************************************************************
    * Function명 : fn_validateBeforeSubmit
    * 설명       : 휴대폰번호 유효성 체크 함수
    ******************************************************************************/
    fn_validateBeforeSubmit (result) {
      if (this.isBeforeSend) {
        // 인증번호 전송
        this.fn_OnSend()
      } else {
        this.fn_endTimer()
        this.fn_OnSend()
      } // 인증번호 재전송
    },

    /******************************************************************************
    * Function명 : fn_OnSend
    * 설명       : 휴대폰 인증번호 전송 함수
    ******************************************************************************/
    fn_OnSend () {
      // let t_This = this
      if (this.singleCheckbox1) {
        if (this.onSendFlag) return
        this.onSendFlag = true
        let pParams = {}
        let telType = ''
        // 통신사 코드 맵핑 1->SKT
        if (this.telecomType.key === '1') telType = 'SKT'
        else if (this.telecomType.key === '2') telType = 'KT'
        else if (this.telecomType.key === '3') telType = 'LGT'
        else if (this.telecomType.key === '4') telType = 'SKTM'
        else if (this.telecomType.key === '5') telType = 'KTM'
        else if (this.telecomType.key === '6') telType = 'LGTM'
        else telType = this.telecomType.key

        if (this.personType === 3) {
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11M2')) // 수익자일때
        } else {
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11M1')) // 계약자/피보험자일때
        }
        pParams.data = {}
        pParams.data.srnId = this.$options.screenId // 대표 화면 명
        pParams.data.custNm = this.info.custNm // 계약자, 피보험자명
        pParams.data.rrn = this.info.rrn + '000000' // TODO 4/30일 반영건
        pParams.data.celno = this.info.celno // 휴대폰 번호
        pParams.data.cmmuScCd = telType // 휴대폰 통신사 코드 SKT, KT ...
        pParams.data.knb = 'SE' // PI(가입설계), SE(청약), RP(법인)
        pParams.data.vuchId = this.routerParam.vuchId // 영수증ID - TODO 4/30일 반영건
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S', this.fn_ServiceFaultHandler)
      } else {
        this.getStore('confirm').dispatch('ADD', '본인확인 서비스 이용 동의에 체크하시면 인증번호가 발송됩니다.')
        this.validateCheck = false
      }
    },

    /******************************************************************************
    * Function명 : fn_StartTimer
    * 설명       : 7분 타이머 Start
    ******************************************************************************/
    fn_StartTimer () {
      let tmpNum = this.limitTime
      this.timer = setInterval(() => {
        if (tmpNum === 0) {
          this.fn_endTimer()
          return
        }
        tmpNum = --tmpNum
        let n = (tmpNum % 60)
        if (n < 10) {
          this.confirmTime = Math.floor(tmpNum / 60) + ':0' + n
        } else {
          this.confirmTime = Math.floor(tmpNum / 60) + ':' + n
        }
      }, 1000)
    },
    /******************************************************************************
    * Function명 : fn_endTimer
    * 설명       : 7분 타이머 end
    ******************************************************************************/
    fn_endTimer () {
      clearInterval(this.timer)
      this.disabledAuthNum = true // 인증번호 TextField
      this.authNumber = '' // 인증번호 model
      this.isBeforeSend = true // 인증번호 만료시간 안보이게
      this.confirmTime = '7:00'
      this.limitTime = 420
    },
    /******************************************************************************
    * Function명 : fn_ServiceResultHandler
    * 설명       : 서비스 호출 결과 함수 정의
    ******************************************************************************/
    fn_ServiceResultHandler (event, serviceName) {
      let lv_Vm = this
      let e = event
      let d = e.data
      switch (serviceName) {
        case 'S':
          if (d.rsltCd) {
            if (d.rsltCd === '0000') {
              this.isBeforeSend = false
              this.fn_StartTimer() // 7분 카운트
              this.disabledAuthNum = false // 인증번호 TextField
              setTimeout(function () {
                lv_Vm.$refs.cNumber.focus()
              }, 100)
              this.authResult = d
            } else {
              this.getStore('confirm').dispatch('ADD', d.rsltCdVal)
              this.isBeforeSend = !!this.isBeforeSend
              this.disabledAuthNum = true // 인증번호 TextField
              this.validateCheck = false
            }
          } else {
            if (event.msgComm) {
              this.getStore('confirm').dispatch('ADD', event.msgComm.msgCd + ' ' + event.msgComm.msgDesc)
            }
          }
          this.onSendFlag = false
          break
        case 'S1':
          if (e && d && d.rsltCd) {
            if (d.rsltCd === '0000') {
              this.isAuthError = false
              this.isAuthCompleted = true
              this.disabled = true
              this.goNext = true
              this.authNumKeyUpNext = false
              this.disabledAuthNum = true
            } else {
              this.isAuthError = true
              this.getStore('confirm').dispatch('ADD', d.rsltCdVal)
            }
          }
          break
        default:
          break
      }
    },

    fn_authFinish () {
      this.disabled = true
      this.cardTwo.isDisabled = false
      this.step = 2
      this.$refs.expandedVal1.isExpand = true
      this.$refs.expandedVal2.isExpand = true
    },
    fn_goNext () {
      this.fn_authFinish()
    },

    /******************************************************************************
    * Function명 : fn_ServiceFaultHandler
    * 설명       : 에러 이벤트
    ******************************************************************************/
    fn_ServiceFaultHandler (event, serviceName) {
      switch (serviceName) {
        case 'S':
          this.validateCheck = false
          this.onSendFlag = false
          break
        case 'S1':
          break
        default:
          break
      }
    },

    onSelectAgreeAllTwo () {
      this.cardTwo.agreeCheckboxList = this.cardTwo.isAllAgree ? this.cardTwo.allItems : []
    },

    /******************************************************************************
    * Function명 : fn_baseData
    * 설명       : fn_baseData
    ******************************************************************************/
    fn_baseData () {
      let lv_Vm = this
      let trnstId = 'txTSSBC16P1'
      let data = {}
      data.cnsltNo = PSServiceManager.getUserInfo('userEno')
      data.chnlCustId = this.routerParam.mobslChnlCustId
      data.custNm = this.routerParam.mobslChnlCustNm

      let commHeader = {
        eaiId: 'C339_F1MDI0270_S',
        fahrTrnId: 'S',
        requestSystemCode: 'F1339',
        reqSrvcNm: '',
        reqMthdNm: '',
        targetSystemCode: '',
        resVONm: 'sli.in.ea2.vo.a0005md.EAF1MDI0270ResVO',
        reqVONm: 'sli.in.ea2.vo.a0005md.EAF1MDI0270ReqVO'
      }
      this.post(lv_Vm, data, trnstId, 'S', commHeader)
        .then(function (response) {
          if (response && response.body) {
            // console.log('지문촬영 fn_baseData : \n' + JSON.stringify(response.body))
            // console.log('나이 : ' + lv_Vm.$bizUtil.getAgeFromKnb((response.body.knb).substring(0, 7)))
            lv_Vm.info = {
              custNm: response.body.custNm,
              rrn: response.body.knb.substr(0, 7),
              celno: response.body.celno.replace('-', '').replace('-', ''),
              knb: response.body.knb,
              age: lv_Vm.$bizUtil.getAgeFromKnb((response.body.knb).substring(0, 7)),
              knbStr: (response.body.knb).substring(0, 6) + '-'+ (response.body.knb).substring(6, 7) + '******',
              knbFrno:(response.body.knb).substring(0, 6),
              knbBkFstNo: (response.body.knb).substring(6, 7)
            }
            lv_Vm.celphTlnum = lv_Vm.info.celno.substring(0, 3)
            lv_Vm.custTelnum = lv_Vm.info.celno.substring(3)
          }
        })
        
    },
    
  /******************************************************************************
   * Function명 : fn_BottomConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_BottomConfirm () {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: '피보험자 지문촬영을 종료하시겠습니까?',
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {tabIdx: 1})
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
   },

   /******************************************************************************
    * Function명 : fn_goBioSign
    * 설명       : 피보험자 지문촬영
    ******************************************************************************/
    fn_goBioSign: function () {
      let lv_vuchId = this.routerParam.vuchId
      let lv_hoshInfo = this.routerParam.hoshInfo
      let lv_bnfrInfo = this.routerParam.bnfrInfo

      // this.$router.push({name: 'TSSPS235M', params: {vuchId: '', hoshInfo: '', bnfrInfo: ''}})
      this.$router.push({name: 'MSPPS720M', params: {vuchId: lv_vuchId, hoshInfo: lv_hoshInfo, bnfrInfo: lv_bnfrInfo}})
    },

    /******************************************************************************
    * 카드 인증 영역
    ******************************************************************************/
    /******************************************************************************
    * Function명  : fn_BtnAuthClick
    * 설명        : 인증하기 버튼 클릭 핸들러
    ******************************************************************************/
    fn_BtnAuthClick () {
      let isValid = true
      let mesg = ''

      this.celphTlnum = String(this.celphTlnum).trim()
      this.custTelnum = String(this.custTelnum).trim().replace(/-/g,'')

      // if(! this.celphTlnum){
      if(this.$bizUtil.isEmpty(this.celphTlnum)){
        isValid = false 
        mesg = '국번을선택하세요'
      }

      // if((! this.custTelnum) || this.custTelnum.length < 7 ){
      if((this.$bizUtil.isEmpty(this.custTelnum)) || this.custTelnum.length < 8 ){
        isValid = false 
        mesg = 'ARS 인증받을 핸드폰 번호를 확인하세요'
      }

      if(isValid){
        // if (this.singleCheckbox2) {
          this.fn_ChkFcTelnum ()
        // } else {
          // this.getStore('confirm').dispatch('ADD', '본인확인 서비스 이용 동의에 체크하세요.')
        // }
      } else {
        this.getStore('toast').dispatch('ADD',mesg)
      }
    },

   /******************************************************************************
    * Function명  : fn_CardCert
    * 설명        : 카드인증 실행
    ******************************************************************************/
    fn_CardCert () {
      let lv_Vm = this
      let phoneNum = this.celphTlnum + this.custTelnum
      let birth = this.$bizUtil.getBirthDateFromKnb(lv_Vm.info.knbFrno + lv_Vm.info.knbBkFstNo)
      let sexCd = this.$bizUtil.getGenderFromKnb(lv_Vm.info.knbFrno + lv_Vm.info.knbBkFstNo) === 'male' ? 'M' : 'F'
      
      let param={ 
          custNm: lv_Vm.info.custNm,
          phone: phoneNum, 
          cpCode: 'RO9900000180',
          birth: birth,
          sexCd: sexCd,
        }

      window.fdpbridge.exec('CardCertPlugin', param
      ,(result)=>{
        console.log('CardCertPlugin result !')
        console.log(result)
        if (result.data === 'Y') {
          lv_Vm.isAuthError2 = false
          lv_Vm.isAuthConfirm = true
          lv_Vm.disabled = true
          lv_Vm.goNext = true
          lv_Vm.isAuthCompleted = true

          // lv_Vm.fn_AuthFinish()
        } else {
          lv_Vm.getStore('confirm').dispatch('ADD', '신용카드인증실패')
        }
      }
      ,(failed)=>{
        lv_Vm.getStore('confirm').dispatch('ADD', '신용카드인증실패')
        console.log('CardCertPlugin failed !')
        console.log(failed)
        lv_Vm.isAuthError2 = true

      })
    },
    
    /******************************************************************************
    * Function명  : fn_AuthFinish
    * 설명        : 인증완료
    ******************************************************************************/
    fn_AuthFinish () {
      this.getStore('toast').dispatch('ADD','카드인증완료')
    },

    /******************************************************************************
    * Function명  : fn_ChkFcTelnum
    * 설명        : 인증완료
    ******************************************************************************/
    fn_ChkFcTelnum () {
      //고객 채널 ID만 넘기면 처리계에서 재조회후 판단함..
      //CMNewCustRegBIZ.selCelnoCheck()

      //this.custCardVO = {}      
      this.custCardVO.chnlCustId = this.routerParam.mobslChnlCustId //this.chnlCustId
      this.custCardVO.celnoFrno = this.celphTlnum
      this.custCardVO.celnoBkno = this.custTelnum
      this.custCardVO.celno = this.celphTlnum + '' + this.custTelnum

      let pParams = this.custCardVO
      
      let lv_Vm = this
      let trnstId = 'txTSSCM10S3'

      //this.chkCelnoResult = false
      //this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, 'S')
      .then(function (response) {
        let celnoErrorMsg = ""
        
        //lv_Vm.isLoading = false

        if (!response.body) {
          celnoErrorMsg = response.msgComm.msgDesc
          lv_Vm.getStore('confirm').dispatch('ADD', celnoErrorMsg)
          return
        }  
        
        if (response.body.celnoStatCo === '00') { // 정상일 경우
          //lv_Vm.chkCelnoResult = true

          lv_Vm.fn_CardCert ()
        } else {

          if (response.body.celnoStatCo === '01') { // 01: 임시등록 번호
            celnoErrorMsg = lv_Vm.$t('ps')['EPSC065'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
          } else { // 02: FC휴대번호와 동일한 경우
            celnoErrorMsg = lv_Vm.$t('ps')['EPSC066'] // '컨설턴트 핸드폰번호로 등록된 번호입니다.'            
          }

          lv_Vm.getStore('confirm').dispatch('ADD', celnoErrorMsg)
        }
        
      })
      .catch(function (error) {
        window.vue.error(error)
      })
       
    },


    _fn_End(){return}//코딩 종료 함수 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>